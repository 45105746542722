import "./styles/index.scss";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import ScrollToTop from "./scrollToTop";
const theme = createTheme({
  palette: {
    primary: {
      main: "#FFCC00", // Your primary color
    },
    secondary: {
      main: "#FF4081", // Your secondary color
    },
  },
  typography: {
    fontFamily: "Roboto, sans-serif", // Your desired font family
  },
});

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <PayPalScriptProvider
        options={{
          clientId:
            "AQpOlwlJJPMSlUjQq4R1gDE9rsFCJ7RchwQyyw-rbAEdzD0v-FxntLbJac6qBpgNNT8-QU-3wo-CPrmS",
          currency: "GBP",
        }}
      >
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </PayPalScriptProvider>
    </BrowserRouter>
  </React.StrictMode>
);
